import React from "react";
import "./Search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { debounce } from "lodash";
import CourseCard from "../CourseCard";
import { Auth } from "aws-amplify";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useTranslation } from "react-i18next";

import configData from "../../config";

export default function Search({ search, setSearch, useWebP }) {
  const modalBodyref = React.createRef();
  const inputRef = React.createRef();
  const [searchResult, setSearchResult] = React.useState([]);
  const [cStatus, setCstatus] = React.useState("init");
  const [loading, setLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    if (search) {
      disableBodyScroll(modalBodyref.current);
    } else {
      clearAllBodyScrollLocks();
    }

    if (inputRef.current) inputRef.current.focus();

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [search, modalBodyref, inputRef]);
  const delayedQuery = React.useCallback(
    debounce((q) => sendQuery(q), 500),
    []
  );

  const searchReset = React.useCallback(() => {
    setSearchResult([]);
    setSearch(false);
    setLoading(false);
    setCstatus("init");
    Array.from(document.querySelectorAll(".modal-input")).forEach(
      (input) => (input.value = "")
    );
  }, []);
  async function sendQuery(keyword) {
    setSearchResult([]);

    if (keyword.length > 0) {
      setLoading(true);
      let user = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });
      let searchResult_ = await fetch(
        `${configData.SERVER_URL}/api/search?search=${keyword}&lang=${
          i18n.language === "ar" ? "ar" : "en"
        }`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user
              ? `Bearer ${user.signInUserSession.accessToken.jwtToken}`
              : "",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          return response.status ? response.data : [];
        });
      setSearchResult(searchResult_.courses);
      if (searchResult_.courses.length === 0) {
        setCstatus("no_result");
      } else {
        setCstatus("result");
      }
    } else {
      setCstatus("init");
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const onChange = (e) => {
    delayedQuery(e.target.value);
  };
  return (
    <div
      ref={modalBodyref}
      className={search ? "modal is-active search-modal" : "modal l-modal"}
    >
      <div
        className="modal-background"

        // onClick={() => {
        //   setSearch(false);
        // }}
      ></div>

      <motion.div
        initial={"initial"}
        animate={search ? "enter" : "initial"}
        variants={{
          initial: {
            opacity: 0,
            y: "-50px",
            transition: {
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          },
          enter: {
            opacity: 1,
            y: "0px",
            transition: {
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          },
        }}
        className="field mb-3"
      >
        <div
          style={i18n.language === "ar" ? { direction: "rtl" } : {}}
          className={`control is-large has-icons-right ${
            loading ? "is-loading" : ""
          }  `}
        >
          <input
            className="input is-white is-medium is-rounded is-focus has-text-danger modal-input"
            type="text"
            placeholder={t("Search Courses")}
            onChange={onChange}
            ref={inputRef}
          />
          {!loading && (
            <span className={`icon has-text-danger is-right`}>
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </span>
          )}
        </div>
      </motion.div>

      <motion.div
        initial={"initial"}
        animate={"enter"}
        variants={{
          initial: {
            // height: 0,
            opacity: 0,
            y: "-50px",
            transition: {
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
              delay: 0.2,
            },
          },
          enter: {
            opacity: 1,
            y: "0px",
            transition: {
              duration: 1,
              ease: [0.43, 0.13, 0.23, 0.96],
              delay: 0.2,
            },
          },
        }}
        className="modal-card"
      >
        {/* <AnimatePresence exitBeforeEnter>
          <motion.section
            variants={{
              init: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
              },
            }}
            animate={cStatus === "result" ? "enter" : "init"}
            className={"modal-card-body"}
          >
            {searchResult.map((x) => {
              return (
                <motion.div
                  key={x._id}
                  initial={"initial"}
                  animate={"enter"}
                  exit={"initial"}
                  variants={{
                    initial: {
                      //   height: 0,
                      opacity: 0,
                      y: "-50px",
                      transition: {
                        duration: 1,
                        ease: [0.43, 0.13, 0.23, 0.96],
                        delay: 0.2,
                      },
                    },
                    enter: {
                      //   height: "auto",
                      opacity: 1,
                      y: "0px",
                      transition: {
                        duration: 1,
                        ease: [0.43, 0.13, 0.23, 0.96],
                        delay: 0.2,
                      },
                    },
                  }}
                  className="my-3"
                >
                  <CourseCard
                    data={x}
                    useWebP={true}
                    modalCallback={setSearch}
                  />
                </motion.div>
              );
            })}
          </motion.section>
        </AnimatePresence> */}

        {cStatus === "no_result" && (
          <motion.div
            key="no_result"
            initial={"initial"}
            animate={"enter"}
            exit={"initial"}
            variants={{
              initial: {
                opacity: 0,
                y: "-50px",
                transition: {
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              },
              enter: {
                opacity: 1,
                y: "0px",
                transition: {
                  duration: 1,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              },
            }}
            className="modal-card-body no-result"
          >
            <h3 className="has-text-centered has-text-danger">
              NO RESULTS FOUND
            </h3>
          </motion.div>
        )}
        <AnimatePresence exitBeforeEnter>
          {cStatus === "result" && (
            <motion.section
              key="result"
              exit="initial"
              variants={{
                initial: {
                  opacity: 0,
                  y: "-50px",
                  transition: {
                    duration: 1,
                    ease: [0.43, 0.13, 0.23, 0.96],
                    delay: 0.2,
                  },
                },
                enter: {
                  opacity: 1,
                  y: "0px",
                  transition: {
                    duration: 1,
                    ease: [0.43, 0.13, 0.23, 0.96],
                    delay: 0.2,
                  },
                },
              }}
              initial={"initial"}
              animate={cStatus === "result" ? "enter" : "initial"}
              className={"modal-card-body"}
            >
              {searchResult.map((x) => {
                return (
                  <motion.div
                    key={x._id}
                    initial={"initial"}
                    animate={"enter"}
                    exit={"initial"}
                    variants={{
                      initial: {
                        //   height: 0,
                        opacity: 0,
                        y: "-50px",
                        transition: {
                          duration: 1,
                          ease: [0.43, 0.13, 0.23, 0.96],
                          delay: 0.2,
                        },
                      },
                      enter: {
                        //   height: "auto",
                        opacity: 1,
                        y: "0px",
                        transition: {
                          duration: 1,
                          ease: [0.43, 0.13, 0.23, 0.96],
                          delay: 0.2,
                        },
                      },
                    }}
                    className="my-3"
                  >
                    <CourseCard
                      data={x}
                      useWebP={useWebP}
                      modalCallback={setSearch}
                    />
                  </motion.div>
                );
              })}
            </motion.section>
          )}
        </AnimatePresence>
      </motion.div>
      <button
        onClick={searchReset}
        className="modal-close is-large is-primary"
        aria-label="close"
      ></button>
    </div>
  );
}
