import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
export default function Card({ tutor, useWebP, selectedTutorCallback }) {
  return (
    <div className="swiper-zoom-container my-3">
      <Link
        to={`/tutor/${tutor.tutor_id}`}
        onClick={() => {
          selectedTutorCallback({
            ...tutor,
          });
        }}
      >
        <CardWrapper>
          <Image
            src={useWebP ? tutor.tutor_image.wp_path : tutor.tutor_image.m_path}
          ></Image>
          <h3 className="is-size-6-touch is-size-5 has-text-weight-bold has-text-danger">
            {tutor.tutor_name}
          </h3>
          <p className="is-size-7-touch is-size-7 has-text-weight-bold has-text-grey-dark">
            {tutor.tutor_profession}
          </p>
        </CardWrapper>
      </Link>
    </div>
  );
}

const CardWrapper = styled.div`
  /* background: tomato; */
  position: relative;
  /* cursor: pointer; */
  padding: 0 0 0.3rem 0;
  margin: 2em auto;
  height: auto;
  border-radius: 10px;
  & h4 {
    text-align: center;
    color: #f48484;
    /* z-index: 1; */
  }
  & p {
    /* font-size: 0.8em; */
  }
`;

const Image = styled.img`
  /* display: block; */
  margin: 2.5% auto auto;
  mask-image: linear-gradient(to bottom, black 40%, transparent 100%);
  height: 90%;
  width: 95%;
  border-radius: 26px;
  @media (max-width: 1024px) {
    border-radius: 15px;
  }
`;
