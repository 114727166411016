import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
// Amplify.configure({
//   Auth: {
//     identityPoolId: "ap-south-1:2e4e430e-dfc2-4700-a695-6227eb0e2339",
//     region: "ap-south-1", // REQUIRED - Amazon Cognito Region
//     userPoolId: "ap-south-1_OYuAEiDE9", //OPTIONAL - Amazon Cognito User Pool ID
//     userPoolWebClientId: "5ues1heaqi63rkcnk0elveg2qm", //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//     AWSS3: {
//       bucket: "educationalcloud-videos154211-dev",
//       region: "ap-south-1",
//     },
//     // customPrefix: {
//     //   public: "tutor-uploads/public/",
//     //   private: "tutor-uploads/private/",
//     //   protected: "tutor-uploads/protected/",
//     // },
//   },
// });
Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
