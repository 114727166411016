import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faLock, faPlay } from "@fortawesome/free-solid-svg-icons";
import "./Episode.scss";
import { Convert } from "../../util/ConvertSec";
import { find } from "lodash";

export default function Episodes({
  episode,
  activeEpisode,
  setActiveEpisode,
  progress,
  watchHistory = [],
  index = 0,
}) {
  const [history, setHistory] = React.useState(
    find(watchHistory, { episode: episode.episode_id })
  );

  React.useEffect(() => {
    setHistory(find(watchHistory, { episode: episode.episode_id }));
  }, [watchHistory, episode]);

  return (
    <div
      className="box has-background-danger mb-2 episode"
      onClick={() => {
        if (history) {
          setActiveEpisode(episode);
        }
      }}
    >
      <div className="media">
        <div className="media-left is-vcentered">
          {activeEpisode &&
            activeEpisode.episode_id !== episode.episode_id &&
            history && (
              <FontAwesomeIcon
                className="has-text-white"
                icon={faPlay}
                size={"2x"}
              ></FontAwesomeIcon>
            )}
          {!history ? (
            index !== 0 ? (
              <FontAwesomeIcon
                className="has-text-white"
                icon={faLock}
                size={"2x"}
              ></FontAwesomeIcon>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="media-content has-text-white">
          <span className="has-text-weight-bold">{episode.title}</span>
          <p className="is-size-7">{episode.description}</p>
          {activeEpisode && activeEpisode.episode_id === episode.episode_id && (
            <>
              <progress
                className="progress is-white is-small mt-2 mb-0"
                value={episode.video_length.ts - progress}
                max={episode.video_length.ts}
              >
                {Convert(episode.video_length.ts)}
              </progress>
              <p className="has-text-right">
                {progress
                  ? Convert(progress)
                  : Convert(episode.video_length.ts)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
