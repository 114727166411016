import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle as faInfo,
  faPlayCircle,
  faClock,
  faThumbsUp,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { Convert } from "../../util/ConvertSec";
export default function MyCourseList({ data, modalCallback, useWebP }) {
  return (
    <>
      <div className="columns box my-2">
        <div className="column is-one-third-desktop">
          <img
            style={{ borderRadius: "16px" }}
            className="image"
            src={useWebP ? data.thumbnail.wp_path : data.thumbnail.m_path}
            alt=""
          />
        </div>
        <div className="column">
          <h3 className="is-size-4 has-text-weight-bold">{data.title}</h3>
          <span
            className="is-rounded has-background-danger has-text-white my-2	px-4"
            style={{
              borderRadius: "16px",
            }}
          >
            {data.tutor_name}
          </span>

          <p className="mt-2">
            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>&nbsp;
            {data.course_length ? Convert(data.course_length) : 0}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>&nbsp;
            {data.likes}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>&nbsp;
            {data.view_count}
          </p>
          <p className="is-size-6 mt-2">
            {data.p_date &&
              `Purchased date : ${dayjs(data.p_date).format(
                "D MMM YYYY - HH:mm A"
              )}`}
          </p>

          <Link
            to={`/tutor/${data.tutor}/${data.course}/true`}
            onClick={() => {
              modalCallback(false);
            }}
            className={`button is-primary is-rounded is-normal has-text-weight-semibold my-2 mx-1`}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faInfo} size={"1x"}></FontAwesomeIcon>
              &nbsp;
            </span>
            <span>Details</span>
          </Link>
          {data.purchased && (
            <Link
              to={`/player/${data.course}`}
              onClick={() => {
                modalCallback(false);
              }}
              className={`button is-link is-rounded is-normal has-text-weight-semibold my-2 mx-1`}
            >
              <span className="icon">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  size={"1x"}
                ></FontAwesomeIcon>
                &nbsp;
              </span>
              <span>Watch course</span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
