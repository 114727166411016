import React from "react";
import "./styles/App.scss";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home";
import CoursePlayer from "./views/CoursePlayer";
import Modal from "./components/Auth/Modal";
import Search from "./components/Search";
import TutorDetail from "./views/TutorDetail";
import TvShows from "./views/TvShows";
import { Auth, Hub } from "aws-amplify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { AnimatePresence } from "framer-motion";
import Language from "./langContext";
import "./i18nTxt";

import { useTranslation } from "react-i18next";

import configData from "./config";
import AboutUs from "./views/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Tos from "./views/Tos";
// import TutorReg from "./views/TutorReg";

function App() {
  const useWebP = isWebpSupported();
  const { i18n } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [authType, setAuthType] = React.useState();
  const [user, setUser] = React.useState(null);
  const [selectedTutor, setSelectedTutor] = React.useState(null);
  const [country, setCountry] = React.useState(null);

  React.useEffect(() => {
    async function GetCountry() {
      let country__ = localStorage.getItem("country");
      if (!country__) {
        let country_ = await fetch(`${configData.SERVER_URL}/api/zone`)
          .then((res) => res.json())
          .then((response) => {
            if (response.status) {
              return response.data.country;
            }
          });
        setCountry(country_);
        localStorage.setItem("country", country_);
      } else {
        setCountry(country__);
      }
    }

    GetCountry();
    setAuthListner();
    if (!user) {
      checkUser();
    }
  }, []);

  React.useEffect(() => {
    if (i18n.language === "ar") {
      document.getElementById("root").style.fontFamily = `Cairo`;
    } else {
      document.getElementById("root").style.fontFamily = `Poppins`;
    }
  }, [i18n.language]);
  const setAuthListner = React.useCallback(async () => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          checkUser();
          setModalIsOpen(false);
          break;
        case "signUp":
          setAuthType("confirmSignup");
          break;
        case "signOut":
          checkUser();
          break;
        case "signIn_failure":
          break;
      }
    });
  }, []);

  async function checkUser() {
    try {
      const User = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });
      if (User) {
        setUser(User);
        if (User.attributes["custom:zone"]) {
          setCountry(User.attributes["custom:zone"]);
        }
        setAuthType("signedIn");
      } else {
        setAuthType("signup");
        setUser(null);
      }
    } catch (err) {
      console.log("checkUser -> err", err);
    }
  }

  function openAuthModal(authType_) {
    setModalIsOpen(true);
    if (authType_) {
      setAuthType(authType_);
      if (authType_ === "signedIn") {
        checkUser();
      }
    }
  }

  function closeAuthModal() {
    setModalIsOpen(false);
    if (authType === "signedIn") {
      checkUser();
    }
  }
  //
  return (
    <Router>
      <Language>
        <Navbar
          user={user}
          useWebP={useWebP}
          authType={authType}
          authCallBack={openAuthModal}
          search
          checkUser={checkUser}
          setSearch={setSearch}
        />
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route exact path={["/", "/categories/:scroll"]}>
              <Home
                useWebP={useWebP}
                authType={authType}
                authCallBack={openAuthModal}
                selectedTutorCallback={setSelectedTutor}
              />
              <Footer />
            </Route>
            <Route path="/tutor/:id/:course?/:scroll?">
              <TutorDetail
                useWebP={useWebP}
                tutor={selectedTutor}
                selectedTutorCallback={setSelectedTutor}
                user={user}
                authCallBack={openAuthModal}
                country={country}
              />
            </Route>
            <Route path="/player/:id">
              <CoursePlayer useWebP={useWebP} />
            </Route>
            <Route path="/tvshows">
              <TvShows useWebP={useWebP} />
            </Route>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/tos">
              <Tos />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            {/* <Route path="/tutor-signup">
              <TutorReg useWebP={useWebP} />
            </Route> */}
          </Switch>
        </AnimatePresence>
        <Search setSearch={setSearch} search={search} useWebP={useWebP} />
        <Modal
          authCallBack={setAuthType}
          modalCallback={closeAuthModal}
          modalIsOpen={modalIsOpen}
          AuthType={authType}
          country={country}
        />
      </Language>
    </Router>
  );
}

export default App;
