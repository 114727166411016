import React, { useEffect, useState, useRef } from "react";
import Swiper, { Navigation } from "swiper";
Swiper.use([Navigation]);

export default function TextSlider({
  sliderClass = "",
  slidesData = [],
  spanStyle,
  fontStyle,
  callBack,
  showNav = false,
  activeId = 0,
}) {
  const sliderRef = useRef(null);
  const [slider, setSlider] = useState(null);

  function changeActive(id, data) {
    if (callBack) {
      callBack(data);
    }
  }

  /* eslint-disable */
  useEffect(() => {
    const slider_ = new Swiper(sliderRef.current, {
      slidesPerView: "auto",
      spaceBetween: 1,
      freeMode: true,
      preloadImages: false,
      navigation: {
        // nextEl: ".swiper-button-next",
        nextEl: `.${sliderClass}-swiper-button-next`,
        prevEl: `.${sliderClass}-swiper-button-prev`,
      },
      observer: true,
    });
    setSlider(slider_);
    return () => {
      if (slider !== null) {
        slider.destroy();
      }
    };
  }, []);

  return (
    <div className={`${sliderClass}`}>
      {showNav && (
        <>
          <div className={`${sliderClass}-swiper-button-prev is-hidden-touch`}>
            {"<"}
          </div>
          <div className={`${sliderClass}-swiper-button-next is-hidden-touch`}>
            {">"}
          </div>
        </>
      )}

      <div ref={sliderRef} className={`swiper-container ${sliderClass}`}>
        <div className="swiper-wrapper">
          {slidesData.map((slide, i) => {
            return (
              <div
                key={slide._id}
                onClick={changeActive.bind(this, i, slide)}
                className={`swiper-slide ${
                  (activeId === slide._id && "active-category") ||
                  (activeId == i && "active-category")
                }`}
              >
                <h3 className={fontStyle}>
                  <span className={spanStyle}> {slide.name}</span>
                </h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
