import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
export default function Slide({ data, useWebP, modalCallback, setActiveShow }) {
  return (
    <div
      className="tv-slider"
      style={{
        backgroundImage: `url(${
          useWebP ? data.thumbnail.wp_path : data.thumbnail.h_path
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="overlay-black">
        <div className="px-3 py-3 content-top">
          <h1 className="is-size-1  has-text-weight-bold mb-2">{data.title}</h1>
          <p className="is-size-6  has-text-weight-bold mb-3">
            {data.description}
          </p>
          <button
            className="button is-primary  is-rounded is-medium has-text-weight-bold py-0"
            onClick={() => {
              setActiveShow(data);
              modalCallback(true);
            }}
          >
            <span className="icon is-small">
              <FontAwesomeIcon
                icon={faPlayCircle}
                size={"1x"}
              ></FontAwesomeIcon>
              &nbsp; &nbsp;
            </span>
            <span>WATCH NOW</span>
          </button>
        </div>
      </div>
    </div>
  );
}
