import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          intro_line_1: "Learn everything",
          intro_line_2: "Anytime",
          intro_line_3: "Any Where",
          Categories: "Categories",
          "Tv Shows": "Tv Shows",
          Search: "Search",
          "My Courses": "My Courses",
          Favourites: "Favourites",
          "Sign Out": "Sign Out",
          Login: "Login",
          "Join Now": "Join Now",
          "Most Popular": "Most Popular",
          "Our Experts": "Our Experts",
          "Our Categories": "Our Categories",
          FAQ: "FAQ",
          APPSTORE: "Download our app from play store and App Store",
          "Sign Up To Receive Our Weekly Newsletter":
            "Sign Up To Receive Our Weekly Newsletter",
          "Stay updated on all new online courses":
            "Stay updated on all new online courses",
          "Enter Your Email": "Enter Your Email",
          Subscribe: "Subscribe",
          "Privacy Policy": "Privacy Policy",
          "Terms of Service": "Terms of Service",
          "About Us": "About Us",
          "Contact Us": "Contact Us",
          "Copyright Khebrat, All right reserved":
            "Copyright © Khebrat, All rights reserved.",
          Courses: "Courses",
          "Watch this course": "Watch this course",
          "Buy Now For": "Buy Now For",
          "Watch Now": "Watch Now",
          "Search Courses": "Search Courses",
        },
      },
      ar: {
        translations: {
          intro_line_1: "تعلم كل شيء",
          intro_line_2: "في أي وقت",
          intro_line_3: "في أي مكان",
          Categories: "التصنيفات",
          "Tv Shows": "برامج تلفزيونية",
          Search: "بحث",
          "My Courses": "دوراتي",

          Favourites: "المفضلة",
          "Sign Out": "خروج",
          Login: "خروج",
          "Join Now": "دخول",
          "Most Popular": "الأكثر شهرة",
          "Our Experts": "خبراؤنا",
          "Our Categories": "فئاتنا",
          FAQ: "التعليمات",
          APPSTORE: "قم بتنزيل تطبيقنا من play store و App Store",
          "Sign Up To Receive Our Weekly Newsletter":
            "سجل لتصلك النشرة الأسبوعية لدينا",
          "Stay updated on all new online courses":
            "ابق على اطلاع بجميع الدورات التدريبية الجديدة",
          "Enter Your Email": "أدخل بريدك الإلكتروني",
          Subscribe: "اشترك",
          "Privacy Policy": "سياسة الخصوصية",
          "About Us": "معلومات عنا",
          "Terms of Service": "Terms of Service",
          "Contact Us": "تواصلوا معنا",
          "Copyright Khebrat, All right reserved":
            "حقوق النشر خبرات ، جميع الحقوق محفوظة",
          Courses: "الدورات",
          "Watch this course": "شاهد هذه الدورة",
          "Buy Now For": "اشتر الآن بقيمة",
          "Watch Now": "شاهد الأن ",
          "Search Courses": "بحث بالطبع",
        },
      },
    },

    fallbackLng: "en",
    debug: false,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    // lng: "ar",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
