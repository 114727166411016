import React from "react";
import TrailerPlayer from "../components/TutorCourses/TrailerPlayer";
import { useParams, Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import configData from "../config";
import Swal from "sweetalert2";
import humanizeDuration from "humanize-duration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as solideHeart,
  faShoppingCart,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp, faHeart } from "@fortawesome/free-regular-svg-icons";

import Comments from "../components/TutorCourses/Comments";

import "../components/TutorCourses/TutorCourses.scss";
import RelatedCourses from "../components/RelatedCourse";
import TutorSlider from "../components/TutorSection/TutorSlider";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function TutorDetail({
  tutor,
  selectedTutorCallback,
  useWebP,
  user,
  authCallBack,
  country,
}) {
  const { t, i18n } = useTranslation();

  const [courses, setCourses] = React.useState([]);
  const [purchasedCourses, setPurchasedCourses] = React.useState([]);
  const [liked, setLiked] = React.useState(false);
  const [favourited, setFavourited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState(null);

  const { id, course, scroll } = useParams();
  const courseRef = React.useRef();
  let query = useQuery();

  React.useEffect(() => {
    async function GetTutorDetails() {
      let data_ = await fetch(
        `${configData.SERVER_URL}/webapi/tutor?tutor=${id}&lang=${
          i18n.language === "ar" ? "ar" : "en"
        }`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user
              ? `Bearer ${user.signInUserSession.accessToken.jwtToken}`
              : "",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status) {
            return response.data[0];
          }
        });

      if (data_) {
        selectedTutorCallback(data_.tutor);
        let courses_ = data_.courses;

        if (data_.purchased) {
          setPurchasedCourses(data_.purchased.courses);
        } else {
          setPurchasedCourses([]);
        }

        setCourses(courses_);
        let price = await getPrice(courses_[0]);
        setPrice(price);

        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      }
    }

    function getPrice(course) {
      return new Promise((res, rej) => {
        try {
          let parsed = JSON.parse(course.country_wise_price);
          res(parsed[country] ? parsed[country].price : course.price);
        } catch (err) {
          res(course ? course.price : 0);
        }
      });
    }
    // eslint-disable-next-line
    GetTutorDetails();
  }, [user, i18n.language, id]);

  async function enrollNow() {
    try {
      let user = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });
      if (user) {
        setLoading(true);

        let payload = await fetch(`${configData.SERVER_URL}/api/payment-url`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
          body: new URLSearchParams({
            course: courses[0]._id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            return data;
          })
          .catch((err) => {
            return err;
          });

        if (payload.status) {
          window.location = payload.data.result;
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        authCallBack("signup");
      }
    } catch (err) {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (query.get("payment")) {
      if (query.get("payment") === "failure") {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "Your payment failed please try again",
          confirmButtonColor: "#ed3946",
        });
      }
    }
  }, []);

  return (
    <div
      className="mt-large container p-5"
      style={{ backgroundColor: "#0000" }}
    >
      {courses.length > 0 && (
        <>
          <TrailerPlayer
            src={courses[0].trailer.path}
            poster={courses[0].thumbnail.h_path}
          />
          <div className="my-3 px-4 columns">
            <div className="column is-four-fifths">
              <h2 className="is-size-3-mobile  is-size-2 has-text-weight-bold has-text-danger">
                {courses[0].title}
              </h2>
              <div
                className="mt-3"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="">
                  <span className="is-size-6 has-text-weight-light has-text-grey">
                    Instructor :{" "}
                  </span>
                  <span className="is-size-6 has-text-dark has-text-weight-semibold">
                    {tutor.tutor_name}
                  </span>
                </div>
                <div>
                  <span className="is-size-6 has-text-weight-light has-text-grey">
                    Class length :{" "}
                  </span>
                  <span className="is-size-6 has-text-dark has-text-weight-semibold">
                    {humanizeDuration(courses[0].course_length * 1000, {
                      round: true,
                    })}
                  </span>
                </div>
                <div>
                  <span className="is-size-6 has-text-weight-light has-text-grey">
                    Category :{" "}
                  </span>
                  <span className="is-size-6 has-text-dark has-text-weight-semibold">
                    {courses[0].cat_name}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="column pt-5"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div className="px-1 course-actions px-3 ">
                <span
                  className="icon is-size-3 "
                  // onClick={changeLikeState.bind(this, course._id)}
                >
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    color={liked ? "#ed3946" : ""}
                  ></FontAwesomeIcon>
                </span>
              </div>
              <div className="px-1 course-actions px-3 ">
                <span
                  className="icon is-size-3"
                  // onClick={changeFavouritedState.bind(this, course._id)}
                >
                  <FontAwesomeIcon
                    icon={favourited ? solideHeart : faHeart}
                    color={favourited ? "#ed3946" : ""}
                  ></FontAwesomeIcon>
                </span>
              </div>
              <div className="px-1 course-actions px-3">
                <span
                  // onClick={shareUrl}
                  className="icon share-icon"
                ></span>
              </div>
            </div>
          </div>
        </>
      )}
      {courses.length > 0 && (
        <>
          {" "}
          <div className="container box">
            <div className="my-3 buttons center-button">
              {!purchasedCourses.includes(courses[0]._id) ? (
                <button
                  onClick={enrollNow}
                  style={i18n.language === "ar" ? { direction: "rtl" } : {}}
                  className={`button is-primary  is-rounded is-medium has-text-weight-semibold px-6 enroll-now ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  <span className="icon is-small mx-1">
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      size={"1x"}
                    ></FontAwesomeIcon>
                    &nbsp;
                  </span>
                  <span className="is-uppercase">
                    {t("Buy Now For")} &nbsp;
                    <strong>{price} QAR</strong>
                  </span>
                </button>
              ) : (
                <Link
                  to={`/player/${courses[0]._id}`}
                  className={`button is-link is-rounded is-medium has-text-weight-semibold px-6 watch-now`}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      size={"1x"}
                    ></FontAwesomeIcon>
                    &nbsp;
                  </span>
                  <span className="is-uppercase">{t("Watch this course")}</span>
                </Link>
              )}
            </div>
            <div
              className="my-2 px-3"
              dangerouslySetInnerHTML={{
                __html: courses[0].description,
              }}
            ></div>

            <div className="mt-6">
              <Comments authCallBack={authCallBack} courseId={courses[0]._id} />
            </div>
          </div>
          <div className="my-4 has-text-centered">
            <h3 className="is-size-2 has-text-weight-bold has-text-danger">
              <span className="has-text-grey-dark">Related Classes in</span>{" "}
              {courses[0].cat_name}
            </h3>

            <div className="p-3">
              <RelatedCourses
                cat_id={courses[0].cat_id}
                tutor_id={id}
                selectedTutorCallback={selectedTutorCallback}
                useWebP={useWebP}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
