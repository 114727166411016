import React from "react";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import khebrat_48x48 from "../../assets/48x48.png";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import configData from "../../config";

dayjs.extend(relativeTime);

export default function Comments({ courseId, authCallBack }) {
  const [comments, setComments] = React.useState([]);
  const [notification, setNotification] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    comment: "",
  });

  React.useEffect(() => {
    async function getComments() {
      let comments_ = await fetch(
        `${configData.SERVER_URL}/api/comments?course=${courseId}`
      )
        .then((res) => res.json())
        .then((response) => {
          return response.status ? response.data : [];
        });

      if (comments_) {
        setComments(comments_);
      }
    }
    if (courseId) {
      getComments();
    }
  }, [courseId]);

  function onchange(e) {
    e.persist();
    setForm(() => ({ ...form, [e.target.name]: e.target.value }));
  }

  function hideNotification() {
    setNotification(null);
  }

  async function saveComment() {
    setNotification(null);
    setLoading(true);
    let user = await Auth.currentAuthenticatedUser().catch(() => {
      return false;
    });
    if (user && courseId) {
      let data = await fetch(`${configData.SERVER_URL}/api/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
        },
        body: new URLSearchParams({
          course: courseId,
          ...form,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });

      if (data.status) {
        Array.from(document.querySelectorAll(".comment-input")).forEach(
          (input) => (input.value = "")
        );
      } else {
        setNotification({
          message: data.message,
        });
      }
    } else {
      authCallBack("signup");
    }

    setLoading(false);
  }

  return (
    <>
      <article className="media">
        <figure className="media-left">
          <FontAwesomeIcon size="3x" icon={faUserCircle}></FontAwesomeIcon>
        </figure>
        <div className="media-content">
          <div className="field">
            <p className="control">
              <textarea
                className="textarea comment-input"
                placeholder="Add a comment..."
                name="comment"
                onChange={onchange}
              ></textarea>
            </p>
          </div>
          {notification && (
            <div
              className={
                notification.type === "success"
                  ? "notification is-success"
                  : "notification is-danger"
              }
            >
              <button onClick={hideNotification} className="delete"></button>
              <span>{notification.message}</span>
            </div>
          )}
          <div className="field">
            <p className="control">
              <button
                onClick={saveComment}
                className={loading ? "button is-loading" : "button "}
              >
                Post comment
              </button>
            </p>
          </div>
        </div>
      </article>

      {comments.map((comment) => {
        return (
          <article className="media" key={comment._id}>
            <figure className="media-left">
              <FontAwesomeIcon size="3x" icon={faUserCircle}></FontAwesomeIcon>
            </figure>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong>{comment.name}</strong>
                  <br />
                  {comment.comment}
                  <br />
                  <small>{dayjs(comment.comment_time).fromNow()}</small>
                </p>
              </div>
              {comment.replied ? (
                <article className="media">
                  <figure className="media-left">
                    <p className="image is-48x48">
                      <img className="is-rounded" src={khebrat_48x48} alt="" />
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p>
                        <strong>Khebrat</strong>
                        <br />
                        {comment.reply_comment}
                        <br />
                        <small>
                          {dayjs(
                            comment.reply_comment_time
                              ? comment.reply_comment_time
                              : comment.comment_time
                          ).fromNow()}
                        </small>
                      </p>
                    </div>
                  </div>
                </article>
              ) : (
                ""
              )}
            </div>
          </article>
        );
      })}
    </>
  );
}
