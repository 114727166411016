import React from "react";
import "./LModal.scss";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
export default function LModal({
  modalIsOpen,
  modalCallback,
  modalTitle,
  modalBody,
}) {
  const containerRef = React.createRef();

  React.useEffect(() => {
    if (modalIsOpen) {
      disableBodyScroll(containerRef.current);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [modalIsOpen]);

  return (
    <div
      ref={containerRef}
      className={modalIsOpen ? "modal is-active l-modal" : "modal l-modal"}
    >
      <div
        className="modal-background"
        onClick={() => {
          modalCallback(false);
        }}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <h3 className="modal-card-title is-size-3 has-text-weight-bold pl-4">
            {modalTitle}
          </h3>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              modalCallback(false);
            }}
          ></button>
        </header>
        <section className="modal-card-body">
          {modalIsOpen ? modalBody : ""}
        </section>
      </div>
    </div>
  );
}
