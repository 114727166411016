import React from "react";
import TrailerPlayer from "../TutorCourses/TrailerPlayer";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faClock } from "@fortawesome/free-solid-svg-icons";
import { Convert } from "../../util/ConvertSec";
import { Link } from "react-router-dom";
export default function Slide({ course, useWebP, slideChangeIndex }) {
  const [vPlayed, setVPlayed] = React.useState(false);
  React.useEffect(() => {
    function changePLayed() {
      setVPlayed((prevState) => {
        if (prevState) {
          return !prevState;
        }
      });
    }

    changePLayed();
  }, [slideChangeIndex]);
  return (
    <>
      <motion.div
        className="swiper-slide"
        initial="initial"
        animate="in"
        exit="out"
        transition={{
          type: "tween",
          ease: "anticipate",
          duration: 1,
        }}
        variants={{
          initial: {
            opacity: 0,
            visibility: 0,
          },
          in: {
            scale: 1,
            opacity: 1,
          },
          out: {
            scale: 0.5,
            opacity: 0,
          },
        }}
      >
        <div className="overlay-dark">
          {!vPlayed ? (
            <img
              src={useWebP ? course.thumbnail.wp_path : course.thumbnail.m_path}
              alt=""
            />
          ) : (
            <TrailerPlayer
              key={course._id}
              vPlayed={vPlayed}
              src={course.trailer.path}
            />
          )}
          <div
            className={vPlayed ? "videoButton" : "videoButton playButton"}
            onClick={() => {
              setVPlayed(!vPlayed);
            }}
          ></div>
        </div>
      </motion.div>
      <Link
        className="has-text-grey-dark"
        to={`/tutor/${course.tutor_id}/${course._id}/true`}
      >
        <div className="columns my-3 px-3 is-mobile category-courses-bottom is-hidden-touch">
          <div className="column is-two-thirds-desktop">
            <h3 className="is-size-3 has-text-weight-bold">
              {course ? course.course_title : "Course Title"}
            </h3>
            <h3 className="is-size-3 has-text-weight-bold has-text-danger">
              {course ? `${course.price} QAR` : "10 QAR"}
            </h3>
          </div>
          <div className="column has-text-centered mt-3">
            <div
              style={{ width: "90%", borderRadius: "16px" }}
              className="has-background-danger has-text-white has-text-weight-bold py-0 mx-4"
            >
              {course ? course.tutor_name : "Tutor Name"}
            </div>
            <div className="level ">
              <div className="level-item">
                <span className="icon is-small is-size-6-mobile mx-4 mt-2 has-text-left">
                  <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>&nbsp;
                  {course.view_count}
                </span>
              </div>
              <div className="level-item">
                <span className="icon is-small is-size-6-mobile mx-4 mt-2">
                  <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>&nbsp;
                  {course ? Convert(course.course_length) : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="category-courses-bottom my-3 px-3 is-hidden-desktop">
          <h3 className="is-size-3 has-text-weight-bold">
            {course ? course.course_title : "Course Title"}
          </h3>
          <div
            style={{ borderRadius: "16px" }}
            className="has-background-danger has-text-centered  has-text-white has-text-weight-bold px-3"
          >
            {course ? course.tutor_name : "Tutor Name"}
          </div>
          <div className="level is-mobile mt-2">
            <div className="level-item">
              <h3 className="is-size-3 has-text-weight-bold has-text-danger">
                {course ? `${course.price} QAR` : "10 QAR"}
              </h3>
            </div>
            <div className="level-item">
              <span className="icon is-small is-size-6-mobile mx-4 mt-2 ">
                <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>&nbsp;
                {course.view_count}
              </span>
            </div>
            <div className="level-item">
              <span className="icon is-small is-size-6-mobile mx-4 mt-2">
                <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>&nbsp;
                {course ? Convert(course.course_length) : 0}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
