/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:2e4e430e-dfc2-4700-a695-6227eb0e2339",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_OYuAEiDE9",
    "aws_user_pools_web_client_id": "5ues1heaqi63rkcnk0elveg2qm",
    "oauth": {}
};


export default awsmobile;