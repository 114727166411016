import React from "react";
import Player from "../components/CoursePlayer/Player";
import Episode from "../components/CoursePlayer/Episode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import { find } from "lodash";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import configData from "../config";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

export default function CoursePlayer({ useWebP }) {
  const { id } = useParams();
  const [pageStatus, setPageStatus] = React.useState("loading");
  const [course, setCourse] = React.useState(null);
  const [episodes, setEpisodes] = React.useState([]);
  const [activeEpisode, setActiveEpisode] = React.useState(null);
  const [watchHistory, setWatchHistory] = React.useState([]);
  const [activeUrl, setActiveUrl] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [cert, setCert] = React.useState(false);
  const [showCert, setShowCert] = React.useState(false);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    async function getCourse() {
      let user = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });

      if (user) {
        let payload = await fetch(
          `${configData.SERVER_URL}/webapi/course?id=${id}&lang=${
            i18n.language === "ar" ? "ar" : "en"
          }`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
            },
          }
        ).then((res) => res.json());

        if (payload.status) {
          //! setting all data
          setCourse(payload.data.course);
          setEpisodes(payload.data.episodes);
          setWatchHistory(payload.data.watch_history);

          if (payload.data.watch_history.length > 0) {
            let episode = find(payload.data.episodes, {
              episode_id: payload.data.watch_history[0].episode,
            });
            setActiveEpisode(episode);
          } else {
            setActiveEpisode(
              payload.data.episodes[0]
                ? payload.data.episodes[0]
                : "5f6e5d0c4ba9e2464196cbe5"
            );
          }
        }
      }
    }
    getCourse();
  }, [i18n.language]);

  React.useEffect(() => {
    async function getEpisodeUrl() {
      let user = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });

      let fetch_url = await fetch(
        `${configData.SERVER_URL}/api/episode?course=${course.course}&&episode=${activeEpisode.episode_id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        }
      ).then((res) => res.json());

      let fetch_watch_history = await fetch(
        `${configData.SERVER_URL}/api/watch-history?course=${course.course}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        }
      ).then((res) => res.json());

      let [payload, watch_history_] = await Promise.all([
        fetch_url,
        fetch_watch_history,
      ]);

      if (watch_history_.status) {
        setWatchHistory(watch_history_.data);
      }
      if (payload.status) {
        setActiveUrl(payload.data);
      }
      setPageStatus("completed");
    }

    if (activeEpisode) {
      setProgress(activeEpisode.video_length.ts);
      getEpisodeUrl();
    }

    if (episodes.length > 0) {
      if (watchHistory.length === episodes.length) {
        if (watchHistory[0].watch_percentage > 80) {
          setShowCert(true);
        }
      }
    }
  }, [activeEpisode]);

  function showFile(blob, user) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/pdf" });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = `${user.attributes["custom:fullname"]}-${
      course.title
    }-${dayjs().format("D MMM YYYY")}.pdf`;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  async function generateCertificate() {
    setCert(true);
    let user = await Auth.currentAuthenticatedUser().catch(() => {
      return false;
    });
    if (user) {
      let blob = await fetch(
        `${configData.SERVER_URL}/api/certificate?course=${course.course}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          },
        }
      ).then((res) => res.blob());
      setCert(false);
      showFile(blob, user);
    }
  }

  if (pageStatus === "error") return <h3> Error </h3>;

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <section className="hero mt-large">
        <div className="hero-body py-6">
          <div className="columns is-centered">
            <div className="column is-two-thirds-desktop ">
              {activeUrl ? (
                <div className="episode-video-player">
                  <Player
                    poster={
                      useWebP
                        ? activeEpisode.thumbnail.wp_path
                        : activeEpisode.thumbnail.h_path
                    }
                    src={activeUrl.video}
                    setProgress={setProgress}
                    activeEpisode={activeEpisode}
                    setActiveEpisode={setActiveEpisode}
                    episodes={episodes}
                    watchHistory={watchHistory}
                    progress={progress}
                    setShowCert={setShowCert}
                  />

                  {error ? (
                    <div className="notification is-primary mt-3">
                      <button
                        onClick={() => {
                          setError(null);
                        }}
                        className="delete"
                      ></button>
                      <p className="has-text-centered">{error}</p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="has-text-centered mt-3">
                    {showCert && (
                      <button
                        onClick={() => {
                          if (watchHistory.length === episodes.length) {
                            generateCertificate();
                          } else {
                            setError(
                              "You Have to Complete All The Course Episode To Generate Certificate"
                            );
                          }
                        }}
                        className={`button is-primary has-text-weight-bold is-rounded px-6 ${
                          cert ? "is-loading" : ""
                        }`}
                      >
                        <span className="icon is-small">
                          <FontAwesomeIcon
                            icon={faCertificate}
                            size={"1x"}
                          ></FontAwesomeIcon>
                          &nbsp;
                        </span>
                        <span>GET CERTIFICATE</span>
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {episodes.length > 0 && (
              <div className="column box">
                <p className="is-size-5 is-text-danger has-text-weight-bold has-text-left">
                  {course ? course.title : ""}
                </p>
                <div className="my-2">
                  {episodes.map((episode, i) => {
                    return (
                      <Episode
                        key={episode.episode_id}
                        episode={episode}
                        activeEpisode={activeEpisode}
                        setActiveEpisode={setActiveEpisode}
                        progress={progress}
                        watchHistory={watchHistory}
                        index={i}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </motion.div>
  );
}
