import React from "react";
import i18n from "./i18nTxt";
import { I18nextProvider } from "react-i18next";

// const LangContext = React.createContext();
// const LangUpdateContext = React.createContext();

export default function LangProvider({ children }) {
  //   const lang_ = localStorage.getItem("lang");

  // return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
  return <>{children}</>;
}
