import React from "react";
// import MyCourseList from "./MyCourseList";
import { Auth } from "aws-amplify";
import CourseCard from "../CourseCard";
import { useTranslation } from "react-i18next";

import configData from "../../config";

export default function MyCourses({ modalCallback, useWebP }) {
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { i18n } = useTranslation();

  React.useEffect(() => {
    async function getUserCourses() {
      let user = await Auth.currentAuthenticatedUser().catch(() => {
        return false;
      });
      if (user) {
        let payload = await fetch(
          `${configData.SERVER_URL}/api/purchased-courses?lang=${
            i18n.language === "ar" ? "ar" : "en"
          }`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
            },
          }
        ).then((res) => res.json());

        if (payload.status) {
          setCourses(payload.data);
        }
      }

      setLoading(false);
    }

    getUserCourses();
  }, [i18n.language]);

  if (loading)
    return (
      <>
        <div className="ph-item" style={{ border: "none" }}>
          <div className="ph-col-4">
            <div className="ph-picture" style={{ borderRadius: "16px" }}></div>
          </div>
          <div className="ph-col-8">
            <div className="ph-row">
              <div className="ph-col-10 big"></div>
              <div className="ph-col-2 empty big"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div className="container my-courses py-3 py-2">
      {courses.length > 0 ? (
        courses.map((x, i) => {
          return (
            <CourseCard
              key={i}
              data={x}
              useWebP={useWebP}
              modalCallback={modalCallback}
            />
          );
        })
      ) : (
        <h3 className="is-size-3 has-text-weight-bold has-text-centered has-text-danger">
          YOU HAVENT PURCHASED ANY COURSE !!
        </h3>
      )}
    </div>
  );
}
