import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
// import "@videojs/themes/dist/forest/index.css";
import "../../styles/Forest.css";
const options = {
  fill: true,
  fluid: true,
  controls: true,
  autoplay: true,
  muted: false,
  preload: "auto",
  controlBar: {
    pictureInPictureToggle: true,
    playbackRateMenuButton: false,
  },
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
  },
};
export default function TrailerPlayer({ src, poster }) {
  const videoRef = React.useRef(null);
  const [player, setPlayer] = React.useState(null);
  React.useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      // sources: [src],
    });
    setPlayer(vjsPlayer);
  }, []);

  React.useEffect(() => {
    if (player !== null) {
      player.src({ src });
      player.poster(poster);
    }
  }, [src, player]);

  return (
    <div className="trailer_palyer">
      <div data-vjs-player>
        <video
          playsInline
          ref={videoRef}
          className="video-js vjs-theme-forest"
        ></video>
      </div>
    </div>
  );
}
