import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import TutorSlider from "./TutorSlider";
import Card from "../TutorCard/TutorCard";
import { useTranslation } from "react-i18next";

// import { isWebpSupported } from "react-image-webp/dist/utils";
// import ContentLoader from "react-content-loader";
import configData from "../../config";

export default function TutorSection({ selectedTutorCallback, useWebP }) {
  const [slidesCount, setslidesCount] = useState();
  const [mpSlides, setMPSlides] = useState([]);
  const [exSlides, setEXSlides] = useState([]);
  const { t, i18n } = useTranslation();

  const GetTutors = useCallback(async () => {
    let home_data = await fetch(
      `${configData.SERVER_URL}/api/home?lang=${
        i18n.language == "ar" ? "ar" : "en"
      }`
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          return {
            most_popular: response.data.most_popular,
            our_experts: response.data.our_experts,
          };
        }
      });
    setMPSlides(
      home_data.most_popular.map((x) => {
        return (
          <Card
            useWebP={useWebP}
            tutor={x}
            selectedTutorCallback={selectedTutorCallback}
          ></Card>
        );
      })
    );
    setEXSlides(
      home_data.our_experts.map((x) => {
        return (
          <Card
            tutor={x}
            selectedTutorCallback={selectedTutorCallback}
            useWebP={useWebP}
          ></Card>
        );
      })
    );
  }, [i18n.language]);

  useEffect(() => {
    GetTutors();
  }, [i18n.language]);

  useLayoutEffect(() => {
    function updateSize() {
      setslidesCount(
        window.innerWidth > 1440
          ? 6
          : window.innerWidth >= 1024
          ? 5
          : window.innerWidth >= 768
          ? 4
          : 2
      );
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="container">
      {mpSlides.length > 0 && (
        <TutorSlider
          heading={t("Most Popular")}
          sliderClass={"most-popular"}
          slidesCount={slidesCount}
          slides={mpSlides}
        />
      )}

      {exSlides.length > 0 && (
        <TutorSlider
          heading={t("Our Experts")}
          sliderClass={"our-experts"}
          slidesCount={slidesCount}
          slides={exSlides}
        />
      )}
    </div>
  );
}
