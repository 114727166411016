import React from "react";
// import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faKey,
  faUser,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import "./modal.scss";
import { Auth } from "aws-amplify";
import { motion, AnimatePresence } from "framer-motion";
import configData from "../../config";

const initialFormState = {
  username: "",
  email: "",
  password: "",
  authCode: "",
};

export default function CustomModal({
  modalIsOpen = false,
  modalCallback,
  authCallBack,
  AuthType = "signup",
  country,
}) {
  // const [country, setCountry] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [formState, updateFormState] = React.useState(initialFormState);
  const [isLoading, setIsLoading] = React.useState(false);

  //! UPDATE THE STATE ONCHANGE OF INPUT
  function onchange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }
  //! UPDATE AUTHSTATE
  function changeAuthType(type) {
    Array.from(document.querySelectorAll(".modal-input")).forEach(
      (input) => (input.value = "")
    );
    authCallBack(type);
  }

  //! SEND VERIFICATION CODE
  async function sendVerificationCode() {
    await Auth.resendSignUp(formState.email).catch((err) => {
      console.log(err);
      return false;
    });
    return true;
  }

  //! SAVE USER TO SERVER
  async function saveUser(attributes, accessToken) {
    // let cred = await Auth.currentUserCredentials();
    let data = await fetch(`${configData.SERVER_URL}/api/save-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
      body: new URLSearchParams({
        email: formState.email,
        name: attributes["custom:fullname"],
        country: attributes["custom:zone"],
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        setError("Somthing went wrong please try again later");
        console.log(err);
        return err;
      });
    return data.status;
  }

  //! LOGIN USER
  async function loginUser() {
    const { email, password } = formState;

    let status = await Auth.signIn(email, password).catch(async (err) => {
      console.log(err);
      if (err.code === "UserNotConfirmedException") {
        await sendVerificationCode();
        changeAuthType("confirmSignup");
        return false;
      }
      setError(err ? String(err.message).replace("username", "email") : null);
      return false;
    });

    if (status) {
      await saveUser(
        status.attributes,
        status.signInUserSession.accessToken.jwtToken
      );
      changeAuthType("signedIn");
      modalCallback();
    }
    return status;
  }

  //! FUNCTION FOR RESEND VERIFICATION CODE
  async function resendVerificationCode() {
    let status = await sendVerificationCode();
    if (status) {
      setError("Verfication code Has been send to your mail");
    }
  }

  //! FUNCTION FOR HANDLING ALL SUBMITS
  async function AuthSubmitter() {
    setError(null);
    setIsLoading(true);

    if (AuthType === "signup") {
      if (formState.username.length === 0) {
        setError("Name is Required");
        setIsLoading(false);
        return false;
      }
      const { email, password, username } = formState;
      let status = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:fullname": username,
          "custom:zone": country,
        },
      }).catch((err) => {
        console.log(err);
        setError(
          err
            ? String(err.message)
                .replace("username", "email")
                .replace("Username", "Email")
            : null
        );
        return false;
      });

      if (status) {
        changeAuthType("confirmSignup");
      }
      setIsLoading(false);
      return true;
    }

    if (AuthType === "login") {
      await loginUser();
      setIsLoading(false);
      return true;
    }

    if (AuthType === "confirmSignup") {
      const { email, authCode } = formState;
      let status = await Auth.confirmSignUp(email, authCode).catch((err) => {
        console.log(err);
        setError(err ? err.message : null);
      });

      if (status) {
        await loginUser().catch((err) => {
          setError(null);
          changeAuthType("login");
        });
      }
      setIsLoading(false);
      return true;
    }

    if (AuthType === "forgotPassword") {
      let status = await Auth.forgotPassword(formState.email).catch((err) => {
        console.log(err);
        setError(
          err
            ? String(err.message)
                .replace("username", "email")
                .replace("Username", "Email")
            : null
        );
        return false;
      });

      if (status) {
        changeAuthType("forgotPasswordSubmit");
      }
      setIsLoading(false);
      return true;
    }

    if (AuthType === "forgotPasswordSubmit") {
      const { email, authCode, password } = formState;
      let status = await Auth.forgotPasswordSubmit(email, authCode, password)
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          setError(
            err
              ? String(err.message)
                  .replace("username", "email")
                  .replace("Username", "Email")
              : null
          );

          return false;
        });

      if (status) {
        await loginUser();
      }
      setIsLoading(false);
      return true;
    }

    setIsLoading(false);
  }

  const transition = {
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    variation_1: {
      exit: { y: "50%", opacity: 0, transition: { delay: 0.8, ...transition } },
      enter: {
        y: "0%",
        opacity: 1,
        transition: { delay: 0.3, ...transition },
      },
    },
    variation_2: {
      exit: {
        // y: "50%",
        opacity: 0,
        transition: { delay: 0.8, ...transition },
      },
      enter: {
        opacity: 1,
        transition: { delay: 0.3, ...transition },
      },
    },
  };

  let mainPart = (
    <>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        variants={animations.variation_2}
      >
        <label className="label">Email</label>
        <div className="control has-icons-left has-icons-right">
          <input
            name="email"
            className="input is-danger is-rounded modal-input"
            type="email"
            onChange={onchange}
            placeholder="your@mail.com"
          />
          <span className="icon is-small is-left ">
            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
          </span>
        </div>
      </motion.div>
      <motion.div
        initial="exit"
        animate="enter"
        exit="exit"
        variants={animations.variation_2}
        className="field"
      >
        <label className="label">Password</label>
        <div className="control has-icons-left has-icons-right">
          <input
            name="password"
            onChange={onchange}
            className="input is-danger is-rounded modal-input"
            type="password"
            placeholder="**********"
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
          </span>
        </div>
      </motion.div>
    </>
  );
  let modalBody;

  if (AuthType === "signup") {
    modalBody = (
      <>
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">Name</label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="username"
              className="input is-danger is-rounded modal-input"
              type="text"
              onChange={onchange}
              placeholder="Name"
            />
            <span className="icon is-small is-left ">
              <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
        {mainPart}
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">Country</label>
          <div className="control has-icons-left has-icons-right">
            <input
              className="input is-danger is-rounded"
              type="text"
              placeholder="Country"
              value={country ? countries[country] : ""}
              readOnly
            />
            <span className="icon is-small is-left ">
              <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
      </>
    );
  }

  if (AuthType === "login") {
    modalBody = <>{mainPart}</>;
  }

  if (AuthType === "confirmSignup") {
    modalBody = (
      <>
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">Verification Code</label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="authCode"
              onChange={onchange}
              autoComplete="false"
              className="input is-danger is-rounded modal-input"
              type="text"
              placeholder="Please enter the verification code send to your mail"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
      </>
    );
  }

  if (AuthType === "forgotPassword") {
    modalBody = (
      <>
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">Email</label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="email"
              className="input is-danger is-rounded modal-input"
              type="email"
              onChange={onchange}
              placeholder="your@mail.com"
            />
            <span className="icon is-small is-left ">
              <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
      </>
    );
  }

  if (AuthType === "forgotPasswordSubmit") {
    modalBody = (
      <>
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">Verification Code</label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="authCode"
              onChange={onchange}
              className="input is-danger is-rounded modal-input"
              type="text"
              autoComplete="false"
              placeholder="Please enter the verification code send to your mail"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
        <motion.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={animations.variation_2}
          className="field"
        >
          <label className="label">New Password</label>
          <div className="control has-icons-left has-icons-right">
            <input
              name="password"
              onChange={onchange}
              className="input is-danger is-rounded modal-input"
              type="password"
              placeholder="**********"
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
            </span>
          </div>
        </motion.div>
      </>
    );
  }

  return (
    <AnimatePresence>
      <motion.div className={modalIsOpen ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={modalCallback} />
        {modalIsOpen && (
          <motion.div
            className="modal-card"
            variants={animations.variation_1}
            initial="exit"
            animate="enter"
            exit="exit"
          >
            <header className="modal-card-head">
              {/* <motion.h3
            variants={animations.variation_2}
            initial="exit"
            animate="enter"
            exit="exit"
            className="modal-card-title is-size-3 has-text-weight-bold pl-4"
          >
            {AuthType === "login"
              ? "Login"
              : AuthType === "signup"
              ? "Sign Up"
              : AuthType === "confirmSignup"
              ? "Confirm Email"
              : AuthType === "forgotPassword"
              ? "Forgot Password"
              : AuthType === "forgotPasswordSubmit"
              ? "Add New Password"
              : ""}
          </motion.h3> */}

              {AuthType === "login" ? (
                <motion.h3
                  key="login"
                  variants={animations.variation_2}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  className="modal-card-title is-size-3 has-text-weight-bold pl-4"
                >
                  Login
                </motion.h3>
              ) : AuthType === "signup" ? (
                <motion.h3
                  key="signup"
                  variants={animations.variation_2}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  className="modal-card-title is-size-3 has-text-weight-bold pl-4"
                >
                  Sign Up
                </motion.h3>
              ) : AuthType === "confirmSignup" ? (
                <motion.h3
                  key="confirmSignup"
                  variants={animations.variation_2}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  className="modal-card-title is-size-3 has-text-weight-bold pl-4"
                >
                  Confirm Email
                </motion.h3>
              ) : AuthType === "forgotPassword" ? (
                <motion.h3
                  key="forgotPassword"
                  variants={animations.variation_2}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  className="modal-card-title is-size-3 has-text-weight-bold pl-4"
                >
                  Forgot Password
                </motion.h3>
              ) : AuthType === "forgotPasswordSubmit" ? (
                <motion.h3
                  key="forgotPasswordSubmit"
                  variants={animations.variation_2}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  className="modal-card-title is-size-3 has-text-weight-bold pl-4"
                >
                  Add New Password
                </motion.h3>
              ) : (
                ""
              )}

              <button
                className="delete"
                aria-label="close"
                onClick={modalCallback}
              ></button>
            </header>

            <section className="modal-card-body">
              {modalBody}

              {error !== null && (
                <div className="notification is-danger">
                  <button
                    className="delete"
                    onClick={() => setError(null)}
                  ></button>
                  {error}
                </div>
              )}
            </section>
            <footer className="modal-card-foot">
              <div className="buttons mb-1">
                <button
                  className={`button is-primary has-text-weight-bold is-medium is-rounded ${
                    isLoading ? "is-loading" : ""
                  }`}
                  onClick={AuthSubmitter}
                >
                  {AuthType === "login"
                    ? "Login"
                    : AuthType === "signup"
                    ? "Sign Up"
                    : AuthType === "confirmSignup"
                    ? "Confirm Email"
                    : AuthType === "forgotPassword"
                    ? "Send Verification Code"
                    : AuthType === "forgotPasswordSubmit"
                    ? "Change Password"
                    : ""}
                </button>

                {AuthType !== "confirmSignup" ? (
                  <button
                    className="button has-text-weight-bold is-medium is-rounded"
                    onClick={modalCallback}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    className="button has-text-weight-bold is-medium is-rounded"
                    onClick={resendVerificationCode}
                    disabled={isLoading}
                  >
                    Resend Verification Code
                  </button>
                )}
              </div>
              {AuthType === "login" && (
                <>
                  <a
                    href="#forgotpassword"
                    onClick={() => {
                      changeAuthType("forgotPassword");
                    }}
                    className="is-link mt-1"
                  >
                    Forgort Password ?
                  </a>
                  <p className="has-text-centered mt-2">
                    Don't have an account ?
                    <a
                      className="is-link"
                      href="#signup"
                      onClick={changeAuthType.bind(this, "signup")}
                    >
                      &nbsp;Sign Up
                    </a>
                  </p>
                </>
              )}

              {AuthType === "signup" && (
                <p className="has-text-centered mt-2">
                  Already have an account ?
                  <a
                    className="is-link"
                    href="#login"
                    onClick={changeAuthType.bind(this, "login")}
                  >
                    &nbsp;Sign In
                  </a>
                </p>
              )}
            </footer>
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

const countries = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas (the)",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory (the)",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands (the)",
  CF: "Central African Republic (the)",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands (the)",
  CO: "Colombia",
  KM: "Comoros (the)",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo (the)",
  CK: "Cook Islands (the)",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic (the)",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (the) [Malvinas]",
  FO: "Faroe Islands (the)",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories (the)",
  GA: "Gabon",
  GM: "Gambia (the)",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (the)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  PS: "Palestine",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic (the)",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands (the)",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands (the)",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger (the)",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands (the)",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines (the)",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation (the)",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan (the)",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan (Province of China)",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands (the)",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates (the)",
  GB: "United Kingdom of Great Britain and Northern Ireland (the)",
  UM: "United States Minor Outlying Islands (the)",
  US: "United States of America (the)",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};
