import React from "react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.scss";
import Slide from "./TvShowSlide";
SwiperCore.use([Pagination, Autoplay]);
export default function TvShowSlider({
  slides,
  useWebP,
  modalCallback,
  setActiveShow,
}) {
  const swiperRef = React.useRef(null);

  return (
    <Swiper
      slidesPerView="auto"
      pagination={{ clickable: true }}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      loop={true}
      observer={"true"}
      spaceBetween={10}
      centeredSlides={true}
      autoplay={true}
      speed={1000}
    >
      {slides.map((x, i) => {
        return (
          <SwiperSlide key={i}>
            <Slide
              data={x}
              modalCallback={modalCallback}
              setActiveShow={setActiveShow}
              useWebP={useWebP}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
