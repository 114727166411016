import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "../styles/Swiper.scss";
import TextSlider from "./TextSlider/TextSlider";

// import CustomSlider from "./Slider/CustomSlider";
// import VideoPlayer from "./VideoSlider/VideoPlayer";
// import { Carousel } from "./VideoSlider/Carousel3D";
// import VideoSlider from "./VideoSlider/VideoSlider";

import CatCourseSlider from "./Categories/CourseSlider";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import configData from "../config";

export default function Categories({ useWebP }) {
  const { t, i18n } = useTranslation();
  const [videoSlides, setVideoSlides] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  // const [activeSubCategory, setActiveSubCategory] = useState(0);
  //! for unmount slider // const [loading, setLoading] = useState(true);
  function updateSubCategory(cat) {
    setActiveCategory(cat._id);
    // setSubCategories(cat.sub_cat);
    // setActiveSubCategory(cat.sub_cat.length > 0 ? cat.sub_cat[0]._id : 0);
  }

  // function updateVideo(sub) {
  //   setActiveSubCategory(sub._id);
  // }

  async function loadCategories() {
    let data_ = await fetch(
      `${configData.SERVER_URL}/webapi/categories?lang=${
        i18n.language === "ar" ? "ar" : "en"
      }`
    )
      .then((res) => res.json())
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });

    setCategories(data_);
    setActiveCategory(data_.length > 0 ? data_[0]._id : 0);
    // setSubCategories(data_.length > 0 ? data_[0].sub_cat : []);
    // setActiveSubCategory(
    //   data_.length > 0
    //     ? data_[0].sub_cat.length > 0
    //       ? data_[0].sub_cat[0]._id
    //       : 0
    //     : 0
    // );
  }

  useEffect(() => {
    loadCategories();
  }, [i18n.language]);

  useEffect(() => {
    async function loadVideos() {
      //! for unmount slider  // setLoading(true);
      let data_ = await fetch(
        `${configData.SERVER_URL}/webapi/courses?cat=${activeCategory}&lang=${
          i18n.language === "ar" ? "ar" : "en"
        }`
      )
        .then((res) => res.json())
        .then((response) => {
          return response.data;
        });

      // if (data_.length < 4 && data_.length > 0) {
      //   for (var i = data_.length; i < 4; i++) {
      //     data_.push(data_[0]);
      //   }
      // }

      setVideoSlides(data_);

      //! for unmount slider // setLoading(false);
      // let cards = data_.map((x) => {
      //   return (
      //     <VideoPlayer
      //       autoplay={false}
      //       controls={false}
      //       data={x}
      //       src={x.trailer.path}
      //       poster={x.thumbnail.wp_path}
      //     />
      //   );
      // });

      // if (cards.length < 4 && cards.length > 0) {
      //   for (var i = cards.length; i < 4; i++) {
      //     cards.push(cards[0]);
      //   }
      // }

      // setVideoSlides(cards);
    }

    if (activeCategory !== 0) {
      loadVideos();
    }
  }, [activeCategory, i18n.language]);

  return (
    <>
      <div className="container mt-6 py-4 has-text-centered">
        <h3 className="is-size-2 has-text-weight-bold has-text-danger">
          {t("Our Categories")}
        </h3>
        <SliderContainerOne>
          <TextSlider
            sliderClass={"main-category"}
            slidesData={categories}
            activeId={activeCategory}
            callBack={updateSubCategory}
            fontStyle="is-size-5 has-text-weight-semibold my-3 mx-3"
            spanStyle="px-5 py-1"
            showNav={true}
          ></TextSlider>
        </SliderContainerOne>
      </div>
      <SliderContainerThree>
        <AnimatePresence exitBeforeEnter>
          <motion.div>
            <CatCourseSlider
              useWebP={useWebP}
              slidesData={videoSlides}
              sliderClass="category-course-slider"
            />
          </motion.div>
        </AnimatePresence>
      </SliderContainerThree>
    </>
  );
}

const SliderContainerOne = styled.div`
  margin: 1em 5% 0.5em 5%;
  @media (max-width: 1024px) {
    /* margin: auto; */
  }
`;

// const SliderContainerTwo = styled.div`
//   margin: 0.5em 15%;
// `;
const SliderContainerThree = styled.div`
  margin: 1rem 5%;
  border-radius: 16px;
  min-height: 50vh;
`;
