import React from "react";
import "./StoreSection.scss";
import store from "../../assets/c_store_section_left.png";
import app_store from "../../assets/app_store_sm.png";
import play_store from "../../assets/play_store_sm.png";
import { useTranslation } from "react-i18next";

export default function StoreSection() {
  const { t, i18n } = useTranslation();
  return (
    <section className="app-store-section hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered is-vcentered">
            <div
              className="column is-three-fifths-widescreen"
              style={i18n.language === "ar" ? { direction: "rtl" } : {}}
            >
              <h3 className="is-size-2 has-text-weight-bold has-text-white  has-text-centered-touch">
                {t("APPSTORE")}
              </h3>
              {/* <br /> */}
              {/* <p className="is-size-6 has-text-white has-text-centered-touch">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet
              </p> */}
              <div className="my-5 has-text-centered-touch">
                <a href="https://www.apple.com/in/ios/app-store/">
                  <img
                    src={app_store}
                    className="app-badge"
                    alt="app store badge"
                  />
                </a>
                <a href="https://play.google.com/">
                  <img
                    src={play_store}
                    className="app-badge"
                    alt="play store badge"
                  />
                </a>
              </div>
            </div>
            <div className="column has-text-centered-touch">
              <img
                src={store}
                alt="an illustration of smartphone"
                className="image mt-6 "
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
