import React from "react";
import arabic_girl from "../../assets/arabic.png";
import white_logo from "../../assets/white_logo.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import "./SecondSection.scss";

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};
export default function SecondSection() {
  const { t, i18n } = useTranslation();
  return (
    <motion.div
      initial="exit"
      animate="enter"
      variants={{
        exit: {
          y: "50%",
          opacity: 0,
          transition: { delay: 0.8, ...transition },
        },
        enter: {
          y: "0%",
          opacity: 1,
          transition: { delay: 0.5, ...transition },
        },
      }}
      className="has-background-info mt-4 second-section"
    >
      <div className="container">
        <div
          className="columns is-centered"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <div className="column py-0 is-hidden-touch">
            <motion.img
              style={{
                flex: 1,
                height: "100%",
              }}
              className="image arabic-girl"
              src={arabic_girl}
              alt="second-section"
              initial="exit"
              animate="enter"
              variants={{
                exit: {
                  x: "-50%",
                  opacity: 0,
                  transition: { delay: 0.8, ...transition },
                },
                enter: {
                  x: "0%",
                  opacity: 1,
                  transition: { delay: 0.5, ...transition },
                },
              }}
            ></motion.img>
          </div>
          <motion.div
            initial="exit"
            animate="enter"
            variants={{
              exit: {
                x: "50%",
                opacity: 0,
                transition: { delay: 0.8, ...transition },
              },
              enter: {
                x: "0%",
                opacity: 1,
                transition: { delay: 0.5, ...transition },
              },
            }}
            className="column right-data px-6 py-6"
          >
            <img
              className="white-logo"
              src={white_logo}
              alt="showing-logo"
            ></img>
            <h3 className="has-text-white is-size-3 has-text-weight-bold">
              Over 120 + Courses
            </h3>
            <p className="has-text-white is-size-6 has-text-centered-touch">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy text of the printing and
              printing and typesetting industry.
            </p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
