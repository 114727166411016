import React from "react";
import Footer from "../components/Footer/Footer";

export default function AboutUs() {
  return (
    <div className="full__screen">
      <div className="hero mt-large">
        <div className="hero-body">
          <div className="container">
            <h3 className="is-size-2 has-text-weight-bold has-text-danger">
              About Us
            </h3>
            <p></p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
