import React from "react";
// import CustomSlider from "../Slider/CustomSlider";
import PropTypes from "prop-types";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation]);
export default function TutorSlider({
  slides,
  slidesCount,
  heading,
  sliderClass,
}) {
  const swiperRef = React.useRef(null);
  const [lastIndex, setLastIndex] = React.useState(slidesCount - 1);
  React.useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideReset();
      swiperRef.current.update();
    }
  }, [slides]);

  return (
    <div className="container mt-6 has-text-centered">
      <h3 className="is-size-2 has-text-weight-bold has-text-danger">
        {heading}
      </h3>

      <Swiper
        slidesPerView={slidesCount}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={() => {
          setLastIndex(swiperRef.current.activeIndex + slidesCount - 1);
          // swiperRef.current.update();
        }}
        className={`${sliderClass}-swiper py-2`}
        navigation
        shortSwipes={true}
        observer={"true"}
      >
        {slides.map((Slide, i) => {
          return (
            <SwiperSlide
              className={`${sliderClass} ${
                i === lastIndex ? sliderClass + " last-slide-one" : sliderClass
              }`}
              key={i}
            >
              {Slide}
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <CustomSlider
        slidesData={slides}
        sliderClass={"our-experts"}
        slidesCount={slidesCount}
      ></CustomSlider> */}
    </div>
  );
}

TutorSlider.propTypes = {
  heading: PropTypes.string.isRequired,
  slidesCount: PropTypes.number,
  slides: PropTypes.array,
};

TutorSlider.defaultProps = {
  slides: [],
};
