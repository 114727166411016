import React from "react";
import Collapse from "./Collapse";
import "./FAQ.scss";
import { useTranslation } from "react-i18next";

import configData from "../../config";

export default function FAQ() {
  const [faq, setFaq] = React.useState([]);
  const { t, i18n } = useTranslation();

  async function getFaq() {
    let faq_ = await fetch(
      `${configData.SERVER_URL}/api/faq?lang=${
        i18n.language === "ar" ? "ar" : "en"
      }`
    )
      .then((res) => res.json())
      .then((response) => {
        return response.status ? response.data : [];
      });

    if (faq_) {
      setFaq(faq_);
    }
  }

  React.useEffect(() => {
    getFaq();
  }, [i18n.language]);

  return (
    <section className="hero faq ">
      <div className="hero-body">
        <div className="container ">
          <h3 className="is-size-2 has-text-centered has-text-weight-bold has-text-danger">
            {t("FAQ")}
          </h3>
          <div
            className="box"
            style={i18n.language === "ar" ? { direction: "rtl" } : {}}
          >
            {faq.map((x) => {
              return (
                <Collapse key={x._id} heading={x.question} body={x.answer} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
