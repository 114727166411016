import React from "react";

import FirstSection from "../components/FirstSection/FirstSection";
import SecondSection from "../components/SecondSection/SecondSection";
import TutorSection from "../components/TutorSection/TutorSection";
import Categories from "../components/Categories";
import StoreSection from "../components/StoreSection/StoreSection";
import NewsLetter from "../components/NewsLetter/NewsLetter";
import FAQ from "../components/FAQ";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};
export default function Home({
  authType,
  authCallBack,
  selectedTutorCallback,
  useWebP,
}) {
  const { scroll } = useParams();
  const categoryRef = React.createRef();
  React.useEffect(() => {
    if (categoryRef.current && scroll) {
      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top: categoryRef.current ? categoryRef.current.offsetTop - 45 : 0,
        });
      }, 300);
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  });

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <FirstSection authType={authType} authCallBack={authCallBack} />
      <SecondSection />
      <TutorSection
        useWebP={useWebP}
        selectedTutorCallback={selectedTutorCallback}
      />
      <div ref={categoryRef}>
        <Categories useWebP={useWebP} />
      </div>

      <StoreSection />
      <FAQ />
      <NewsLetter />
    </motion.div>
  );
}
