import React from "react";
import news_letter_img from "../../assets/new_letter.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function NewsLetter() {
  const { t, i18n } = useTranslation();

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container box">
          <div
            className="columns is-centered"
            style={i18n.language === "ar" ? { direction: "rtl" } : {}}
          >
            <div className="column is-4 is-hidden-touch">
              <img src={news_letter_img} alt="news letter illustration" />
            </div>
            <div className="column is-7">
              <h4 className="is-size-3 has-text-weight-bold has-text-danger">
                {t("Sign Up To Receive Our Weekly Newsletter")}
              </h4>
              <p className="is-size-6">
                {t("Stay updated on all new online courses")}
              </p>
              <div className="field mt-4">
                <label>{t("Enter Your Email")}</label>
                <div className="control">
                  <input
                    className="input is-rounded is-danger"
                    type="text"
                    placeholder="your@email.com"
                  />
                </div>
              </div>
              <div className="field">
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="button is-primary is-rounded has-text-weight-bold is-fullwidth"
                >
                  {t("Subscribe")}
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
