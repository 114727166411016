import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
// import "@videojs/themes/dist/city/index.css";
import "../../styles/Forest.css";
import "./Player.scss";
import { Auth } from "aws-amplify";
import { findIndex, find } from "lodash";
import "videojs-seek-buttons";
import configData from "../../config";

const options = {
  playbackRates: [0.5, 1, 1.5],
  fill: true,
  fluid: true,
  controls: true,
  autoplay: true,
  muted: false,
  preload: "auto",
  disableVideoPlayPauseClick: true,
  controlBar: {
    pictureInPictureToggle: false,
    playbackRateMenuButton: true,
    progressControl: {
      seekBar: false,
    },
  },
  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
  },
};

export default function Player({
  src,
  poster,
  setProgress,
  activeEpisode,
  setActiveEpisode,
  episodes,
  watchHistory,
  setShowCert,
}) {
  const videoRef = React.useRef(null);
  const [player, setPlayer] = React.useState(null);

  async function updateHistory(progress) {
    console.log("history updated");
    let user = await Auth.currentAuthenticatedUser().catch(() => {
      return false;
    });

    if (user) {
      progress = progress
        ? progress
        : JSON.parse(localStorage.getItem("watch_time"));
      await fetch(`${configData.SERVER_URL}/api/watch-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
        },
        body: new URLSearchParams(progress),
      }).then((res) => res.json());
    }
  }

  React.useEffect(() => {
    let history = find(watchHistory, { episode: activeEpisode.episode_id });

    if (!history) {
      console.log("no history update");
      updateHistory({
        course: activeEpisode._id,
        episode: activeEpisode.episode_id,
        watch_time: 0,
        watch_percentage: 0,
      });
    }
    let vjsPlayer = videojs(videoRef.current, {
      ...options,
      sources: [src],
    });
    vjsPlayer.seekButtons({
      // forward: 15,
      back: 15,
    });
    if (history) {
      if (history.watch_time !== activeEpisode.video_length.ts) {
        console.log("updated time");
        vjsPlayer.currentTime(history.watch_time);
      }
    }
    setPlayer(vjsPlayer);

    return () => {
      if (player !== null) {
        updateHistory();
        player.dispose();
      }
    };
  }, []);

  React.useEffect(() => {
    if (player !== null) {
      player.poster(poster);
      player.src({ src });
      // player.play();

      player.on("ended", async function () {
        let index = findIndex(episodes, activeEpisode);
        await updateHistory({
          course: activeEpisode._id,
          episode: activeEpisode.episode_id,
          watch_time: 0,
          watch_percentage: 100,
        });

        if (index !== -1 && index !== episodes.length - 1) {
          let activeEpisode_ = episodes[index + 1];
          await setActiveEpisode(activeEpisode_);
          await updateHistory({
            course: activeEpisode_._id,
            episode: activeEpisode_.episode_id,
            watch_time: 0,
            watch_percentage: 0,
          });
        }

        if (index === episodes.length - 1) {
          setShowCert(true);
        }
      });

      player.on(["pause"], async () => {
        await updateHistory();
        return true;
      });

      player.on("timeupdate", () => {
        if (player.played().length > 0) {
          console.log("time_updated");
          setProgress(player.remainingTime());
          localStorage.setItem(
            "watch_time",
            JSON.stringify({
              course: activeEpisode._id,
              episode: activeEpisode.episode_id,
              watch_time:
                activeEpisode.video_length.ts - player.remainingTime(),
              watch_percentage: 0,
            })
          );
        }
      });
    }
  }, [src, poster, player]);

  React.useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      updateHistory();
    });

    return () => {
      window.removeEventListener("beforeunload", (e) => {
        e.preventDefault();
        updateHistory();
      });
    };
  }, []);

  return (
    <div
      data-vjs-player
      style={{
        borderRadius: "16px",
        outline: "none",
        userSelect: "none",
        backgroundColor: "#fff",
      }}
    >
      <video
        poster={poster}
        disablePictureInPicture
        style={{
          borderRadius: "16px",
          outline: "none",
          userSelect: "none",
          backgroundColor: "#fff",
        }}
        ref={videoRef}
        className="video-js vjs-theme-forest"
      ></video>
    </div>
  );
}
