export function Convert(seconds) {
  seconds = parseInt(seconds, 10);

  if (isNaN(seconds)) {
    throw new TypeError("Invalid value sent to convert-seconds");
  }
  var results = {};
  results.hours = Math.floor(seconds / 60 / 60);
  results.minutes = Math.floor((seconds / 60) % 60);
  results.seconds = Math.floor(seconds % 60);

  return `${
    results.hours > 0
      ? `${Math.trunc(results.hours / 10)}${Math.trunc(results.hours % 10)}:`
      : ""
  }${
    results.minutes > 0
      ? `${Math.trunc(results.minutes / 10)}${Math.trunc(
          results.minutes % 10
        )}:`
      : "00:"
  }${
    results.seconds > 0
      ? `${Math.trunc(results.seconds / 10)}${Math.trunc(results.seconds % 10)}`
      : "00"
  }`;
}
