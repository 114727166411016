import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
export default function Collapse({ heading = "", body }) {
  const [isActive, setIsActive] = React.useState(false);

  return (
    <motion.div
      className="my-3 card"
      variants={{
        exit: {
          y: "50%",
          opacity: 0,

          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        },
        enter: {
          y: "0%",
          opacity: 1,

          transition: {
            //   delay: 0.5,
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        },
      }}
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <header
        className={`card-header has-background-danger ${
          isActive ? "active-header" : ""
        }`}
        onClick={() => setIsActive((prev) => !prev)}
      >
        <p className="card-header-title is-size-5 has-text-white ">{heading}</p>
        <FontAwesomeIcon
          className="has-text-right has-text-white my-3 mx-3"
          size="2x"
          icon={faAngleDown}
        ></FontAwesomeIcon>
      </header>

      <AnimatePresence>
        {isActive && (
          <motion.div
            variants={{
              exit: {
                y: "-30%",
                opacity: 0,
                height: 0,
                // display: "none",
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              },
              enter: {
                y: "0%",
                opacity: 1,
                height: "auto",
                // display: "block",
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              },
            }}
            initial="exit"
            animate="enter"
            exit="exit"
            className="collapse-content"
          >
            <p className="is-size-6 px-3 py-3">{body}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
