import React from "react";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default function TvShowCard({
  data,
  useWebP,
  modalCallback,
  setActiveShow,
}) {
  return (
    <div
      className="card tv-show-card"
      style={{ borderRadius: "16px", height: "100%" }}
    >
      <div className="card-image">
        <figure className="image is-16by9">
          <img
            src={useWebP ? data.thumbnail.wp_path : data.thumbnail.h_path}
            alt="Tv Show thumbnail"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4 has-text-weight-bold">{data.title}</p>
            <p className="subtitle is-7">
              {dayjs(data.createdAt).format("HH:mm A - D MMM YYYY")}
            </p>
            {/* <button
              className="button is-primary is-rounded is-medium has-text-weight-bold is-full-width"
              onClick={() => {
                setActiveShow(data);
                modalCallback(true);
              }}
            >
              <span className="icon is-small">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  size={"1x"}
                ></FontAwesomeIcon>
                &nbsp;
              </span>
              <span>WATCH NOW</span>
            </button> */}
          </div>
        </div>
        <div className="content">
          <div
            className="tv-show-watch-now mb-4"
            onClick={() => {
              setActiveShow(data);
              modalCallback(true);
            }}
          >
            <button className="button is-primary is-rounded is-medium has-text-weight-bold is-full-width">
              <span className="icon is-small">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  size={"1x"}
                ></FontAwesomeIcon>
                &nbsp;
              </span>
              <span>WATCH NOW</span>
            </button>
          </div>
          <p className="subtitle is-6"> {data.description}</p>
        </div>
      </div>
    </div>
  );
}
