import React from "react";
import "./Footer.scss";
import footer_logo from "../../assets/khebrat_footer_logo.svg";

import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import youtube from "../../assets/youtube.svg";
import app_store from "../../assets/app_store_sm.png";
import play_store from "../../assets/play_store_sm.png";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer mb-0 pb-1 hero-foot">
      <div className="container">
        <div className="columns is-centered is-2">
          <div className="column has-text-centered">
            <img src={footer_logo} alt="khebrat logo" />
            <div className="align-straight">
              <div className="mx-4">
                <a href="https://www.facebook.com/">
                  <img
                    src={facebook}
                    alt="facebook"
                    className="image is-24x24"
                  />
                </a>
              </div>
              <div className="mx-4">
                <a href="https://www.instagram.com/">
                  <img
                    src={instagram}
                    alt="instagram"
                    className="image is-24x24"
                  />
                </a>
              </div>
              <div className="mx-4">
                <a href="https://twitter.com/">
                  <img src={twitter} alt="twitter" className="image is-24x24" />
                </a>
              </div>
              <div className="mx-4">
                <a href="https://www.youtube.com/">
                  <img src={youtube} alt="youtube" className="image is-24x24" />
                </a>
              </div>
            </div>
          </div>
          <div className="column has-text-centered">
            <div className="my-3">
              <a href="/about-us" className="has-text-white">
                {t("About Us")}
              </a>
            </div>
            <div className="my-3">
              <a href="/tos" className="has-text-white">
                {t("Terms of Service")}
              </a>
            </div>
            <div className="my-3">
              <a href="/privacy-policy" className="has-text-white">
                {t("Privacy Policy")}
              </a>
            </div>
            {/* <div className="my-3">
              <a href="/privacy-policy" className="has-text-white">
                {t("Contact Us")}
              </a>
            </div> */}

            {/* <div className="level is-mobile">
              <div className="level-item">
                <a href="/privacy-policy" className="has-text-white">
                  Privacy Policy
                </a>
              </div>
              <div className="level-item">
                <a href="/privacy-policy" className="has-text-white">
                  About Us
                </a>
              </div>
              <div className="level-item">
                <a href="/privacy-policy" className="has-text-white">
                  Contact Us
                </a>
              </div>
            </div>
          */}

            {/* <div className="align-straight">
              <div className="mx-4">
                <a href="https://www.apple.com/in/ios/app-store/">
                  <img
                    src={app_store}
                    className="app-badge"
                    alt="app store badge"
                  />
                </a>
              </div>
              <div className="mx-4">
                <a href="https://play.google.com/">
                  <img
                    src={play_store}
                    className="app-badge"
                    alt="play store badge"
                  />
                </a>
              </div>
            </div>
           */}
          </div>
          <div className="column has-text-centered">
            <div className="align-straight">
              <div className="mx-4">
                <a href="https://www.apple.com/in/ios/app-store/">
                  <img
                    src={app_store}
                    className="app-badge"
                    alt="app store badge"
                  />
                </a>
              </div>
              <div className="mx-4">
                <a href="https://play.google.com/">
                  <img
                    src={play_store}
                    className="app-badge"
                    alt="play store badge"
                  />
                </a>
              </div>
            </div>

            {/* <div className="mx-4">
              <a href="https://www.apple.com/in/ios/app-store/">
                <img
                  src={app_store}
                  className="app-badge"
                  alt="app store badge"
                />
              </a>
            </div>
            <div className="mx-4">
              <a href="https://play.google.com/">
                <img
                  src={play_store}
                  className="app-badge"
                  alt="play store badge"
                />
              </a>
            </div>
           */}
          </div>
        </div>
      </div>
      <div className="content has-text-centered mt-5">
        <p>{t("Copyright Khebrat, All right reserved")}</p>
      </div>
    </footer>
  );
}
