import React from "react";
// import styled from "styled-components";
import Logo from "../../assets/Logo.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./navBar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LModal from "../Modal/LModal";
import MyCourse from "../MyCourses";
import Favourites from "../Favourites";
import { Auth } from "aws-amplify";
import {
  faUserCircle,
  faSignOutAlt,
  faBook,
  faHeart,
  // faUser,
  faSearch,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Navbar({
  authCallBack = () => {},
  authType,
  user,
  useWebP,
  setSearch,
}) {
  const [isActive, setisActive] = React.useState(false);
  const [userMenu, setUserMenu] = React.useState(false);
  const [modalIsOpen, modalCallback] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [modalBody, setModalBody] = React.useState(null);
  const { t, i18n } = useTranslation();

  function openLargeModal(title, body) {
    modalCallback(true);
    setTitle(title);
    setModalBody(body);
    setUserMenu(false);
    setisActive(false);
  }

  return (
    <>
      {/* is-fixed-top */}
      <nav
        className="navbar is-fixed-top is-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/">
            <img className="image px-1 py-1 mr-6" alt="" src={Logo} />
          </Link>
          <a
            role="button"
            href="#hamburger"
            className={`navbar-burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => {
              setisActive(!isActive);
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-start">
            {/* <Link to="/tvshows" className="navbar-item">
              <motion.span
                href="#courses"
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{ scale: 0.9 }}
              >
                Courses
              </motion.span>
            </Link> */}
            <Link
              className="navbar-item"
              to="/categories/0"
              onClick={() => {
                setisActive(false);
              }}
            >
              <motion.span
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{ scale: 0.9 }}
              >
                {t("Categories")}
              </motion.span>
            </Link>
            {/* <Link
              to="/tvshows"
              onClick={() => {
                setisActive(false);
              }}
              className="navbar-item"
            >
              <motion.span
                whileHover={{
                  scale: 1.2,
                }}
                whileTap={{ scale: 0.9 }}
              >
                {t("Tv Shows")}
              </motion.span>
            </Link> */}

            {authType === "signedIn" && (
              <>
                {/* <hr className="dropdown-divider" />
                <a
                  href="#myprofile"
                  className="button is-white has-text-left navbar-item is-hidden-desktop"
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                  </span>
                  <span>My Profile</span>
                </a> */}
                <hr className="dropdown-divider" />
                <a
                  href="#mycourses"
                  className="button is-white has-text-left navbar-item is-hidden-desktop"
                  onClick={openLargeModal.bind(
                    this,
                    t("My Courses"),
                    <MyCourse modalCallback={modalCallback} useWebP={useWebP} />
                  )}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                  </span>
                  <span> {t("My Courses")}</span>
                </a>
                <hr className="dropdown-divider" />
                <a
                  href="#favourites"
                  className="button is-white has-text-left navbar-item is-hidden-desktop"
                  onClick={openLargeModal.bind(
                    this,
                    t("Favourites"),
                    <Favourites
                      modalCallback={modalCallback}
                      useWebP={useWebP}
                    />
                  )}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
                  </span>
                  <span> {t("Favourites")}</span>
                </a>
                <hr className="dropdown-divider " />
                <a
                  href="#singout"
                  className="button is-white is-fullwidth navbar-item is-hidden-desktop"
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon>
                  </span>
                  <span> {t("Sign Out")}</span>
                </a>
              </>
            )}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <button
                className="button is-white mr-2 is-rounded search-button is-light has-text-weight-semibold is-fullwidth"
                title={t("Search")}
                onClick={() => {
                  setSearch(true);
                  setisActive(false);
                }}
              >
                <span> {t("Search")}</span>
                <span className="icon">
                  <FontAwesomeIcon size="1x" icon={faSearch}></FontAwesomeIcon>
                </span>
              </button>
            </div>
            <div className="navbar-item">
              <button
                className="button is-white is-rounded search-button is-light has-text-weight-semibold is-fullwidth"
                title="Language"
                onClick={() => {
                  i18n.changeLanguage(
                    i18n.language.replace("-US", "") === "en" ? "ar" : "en"
                  );
                  return;
                }}
              >
                <span>{i18n.language.replace("-US", "")}</span>
                <span className="icon">
                  <FontAwesomeIcon size="1x" icon={faGlobe}></FontAwesomeIcon>
                </span>
              </button>
            </div>

            <div className="navbar-item">
              {authType !== "signedIn" ? (
                <>
                  <motion.button
                    className="button is-light is-rounded has-text-weight-bold mr-3 is-fullwidth"
                    whileHover={{
                      scale: 1.2,
                    }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setisActive(false);
                      authCallBack("login");
                    }}
                  >
                    {t("Login")}
                  </motion.button>
                  <motion.button
                    className="button is-primary is-rounded has-text-weight-bold mr-3 is-fullwidth"
                    whileHover={{
                      scale: 1.2,
                    }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => {
                      setisActive(false);
                      authCallBack("signup");
                    }}
                  >
                    {t("Join Now")}
                  </motion.button>
                </>
              ) : (
                <>
                  <div
                    className={`dropdown is-right is-hidden-touch ${
                      userMenu ? "is-active" : ""
                    }`}
                  >
                    <div className="dropdown-trigger">
                      <button
                        className="mr-3 button is-white is-rounded"
                        onClick={() => {
                          setUserMenu(!userMenu);
                        }}
                      >
                        <FontAwesomeIcon
                          size="2x"
                          icon={faUserCircle}
                        ></FontAwesomeIcon>
                      </button>
                    </div>
                    <div
                      className="dropdown-menu"
                      id="dropdown-menu2"
                      role="menu"
                    >
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <h3 className="is-size-6 has-text-black has-text-centered">
                            {user
                              ? user.attributes
                                ? user.attributes["custom:fullname"]
                                : ""
                              : ""}
                          </h3>
                        </div>
                        {/* <hr className="dropdown-divider" />
                        <a
                          href="#myprofile"
                          className="button dropdown-item is-white  has-text-left"
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                          </span>
                          <span>My Profile</span>
                        </a> */}
                        <hr className="dropdown-divider" />
                        <button
                          onClick={openLargeModal.bind(
                            this,
                            t("My Courses"),
                            <MyCourse
                              modalCallback={modalCallback}
                              useWebP={useWebP}
                            />
                          )}
                          className="button dropdown-item is-white  has-text-left"
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                          </span>
                          <span> {t("My Courses")}</span>
                        </button>
                        <hr className="dropdown-divider" />
                        <button
                          href="#favourites"
                          className="button dropdown-item is-white has-text-left"
                          onClick={openLargeModal.bind(
                            this,
                            t("Favourites"),
                            <Favourites
                              modalCallback={modalCallback}
                              useWebP={useWebP}
                            />
                          )}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
                          </span>
                          <span> {t("Favourites")}</span>
                        </button>
                        <hr className="dropdown-divider" />
                        <div className="dropdown-item">
                          <button
                            onClick={() => {
                              Auth.signOut();
                              setUserMenu(false);
                            }}
                            className="button is-white is-fullwidth"
                          >
                            <span className="icon">
                              <FontAwesomeIcon
                                icon={faSignOutAlt}
                              ></FontAwesomeIcon>
                            </span>
                            <span> {t("Sign Out")}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      {authType === "signedIn" && (
        <LModal
          modalIsOpen={modalIsOpen}
          modalCallback={modalCallback}
          modalTitle={title}
          modalBody={modalBody}
        />
      )}
    </>
  );
}

// const Image = styled.img`
//   margin: 5px 60px 5px 10px;
//   height: 4rem;
// `;
