import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import Card from "../TutorCard/TutorCard";

import { useTranslation } from "react-i18next";
import configData from "../../config";
import "../../styles/Swiper.scss";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation]);

export default function RelatedCourses({
  cat_id,
  tutor_id,
  selectedTutorCallback,
  useWebP,
}) {
  const [slidesCount, setslidesCount] = useState(0);
  const [slides, setSlides] = useState([]);
  const { t, i18n } = useTranslation();
  const [lastIndex, setLastIndex] = React.useState(0);
  const swiperRef = React.useRef(null);

  const getRelatedCourse = useCallback(async () => {
    let data = await fetch(
      `${configData.SERVER_URL}/api/related-courses?lang=${
        i18n.language == "ar" ? "ar" : "en"
      }&cat_id=${cat_id}&tutor_id=${tutor_id}`
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          return response.data;
        }
      });

    setSlides(data);
  });

  useEffect(() => {
    let slideCount_ =
      window.innerWidth > 1440
        ? 6
        : window.innerWidth >= 1024
        ? 5
        : window.innerWidth >= 768
        ? 4
        : 2;

    setslidesCount(slideCount_);

    setLastIndex(slideCount_ - 1);
  }, []);

  useEffect(() => {
    getRelatedCourse();
  }, [i18n.language, tutor_id]);

  return (
    <>
      {slides.length > 0 && (
        <Swiper
          slidesPerView={slidesCount}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={() => {
            setLastIndex(swiperRef.current.activeIndex + slidesCount - 1);
            swiperRef.current.update();
          }}
          className={`our-experts-swiper py-2`}
          navigation
          // shortSwipes={true}
          observer={"true"}
        >
          {slides.map((tutor, i) => {
            return (
              <SwiperSlide
                className={`related-course ${
                  i === lastIndex
                    ? "related-course" + " last-slide-one"
                    : "related-course"
                }`}
                key={i}
              >
                <Card
                  useWebP={useWebP}
                  tutor={tutor}
                  selectedTutorCallback={selectedTutorCallback}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
}
