import React from "react";

import TvShowCard from "../components/TvShows/TvShowCard";
import TvShowSlider from "../components/TvShows/TvShowSlider";
import LModal from "../components/Modal/LModal";
import TrailerPlayer from "../components/TutorCourses/TrailerPlayer";
import { take, chunk } from "lodash";
import "../styles/TvShows.scss";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import configData from "../config";

const pageVariants = {
  initial: {
    opacity: 0,
    // x: "100vw",
  },
  in: {
    opacity: 1,
    // x: 0,
  },
  out: {
    opacity: 0,
    // x: "-100vw",
  },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

export default function TvShows({ useWebP }) {
  const [slides, setSlides] = React.useState([]);
  const [tvShows, setTvShows] = React.useState([]);
  const [modalIsOpen, modalCallback] = React.useState(false);
  const [activeTvShow, setActiveShow] = React.useState(null);
  const { i18n } = useTranslation();
  React.useEffect(() => {
    async function getTvShows() {
      let payload = await fetch(
        `${configData.SERVER_URL}/api/tv-shows?lang=${
          i18n.language === "ar" ? "ar" : "en"
        }`
      ).then((res) => res.json());
      if (payload.status) {
        setSlides(take(payload.data, 3));
        setTvShows(chunk(payload.data, 4));
      }
    }

    getTvShows();
  }, [i18n.language]);

  return (
    <>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <section className="hero mt-large">
          <div className="hero-body py-6">
            {slides.length > 0 && (
              <TvShowSlider
                slides={slides}
                useWebP={useWebP}
                modalCallback={modalCallback}
                setActiveShow={setActiveShow}
              />
            )}
            <div className="mt-3 remaining-tv-shows">
              {tvShows.map((x, i) => {
                return (
                  <div className="columns is-desktop" key={i}>
                    {x.map((y, i) => {
                      return (
                        <div className="column is-3" key={y._id}>
                          <TvShowCard
                            data={y}
                            useWebP={useWebP}
                            modalCallback={modalCallback}
                            setActiveShow={setActiveShow}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </motion.div>
      <LModal
        modalIsOpen={modalIsOpen}
        modalCallback={modalCallback}
        modalTitle={activeTvShow ? activeTvShow.title : ""}
        modalBody={
          <div className="container">
            <TrailerPlayer src={activeTvShow ? activeTvShow.video.path : ""} />
          </div>
        }
      />
    </>
  );
}
