import React from "react";
import SwiperCore, { EffectCoverflow, Parallax, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "../TutorCourses/CourseSlider.scss";
import Slide from "./Slide";
SwiperCore.use([EffectCoverflow, Parallax, Navigation]);
export default function CatCourseSlider({ slidesData, useWebP, sliderClass }) {
  const swiperRef = React.useRef(null);
  const [activeSlide, setActiveSlide] = React.useState(0);
  React.useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, [slidesData]);

  function slideChange() {
    if (swiperRef.current) {
      let currentIndex = swiperRef.current.realIndex;
      setActiveSlide(currentIndex);
    }
  }

  return (
    <Swiper
      slidesPerView={1}
      className="category-course-swiper"
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      loop={false}
      onSlideChange={slideChange}
      navigation
      shortSwipes={true}
      centeredSlides={true}
      effect={"coverflow"}
      coverflowEffect={{
        rotate: 0,
        stretch: 300, // Stretch value between each slide (negative value is far away)
        depth: 400, //The larger the value, the more the image will go backwards (can be negative)
        modifier: 1,
        slideShadows: false,
      }}
      observer={"true"}
      parallax={"true"}
      breakpoints={{
        1023: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
      }}
    >
      {slidesData.map((x, i) => {
        return (
          <SwiperSlide key={i} className={sliderClass}>
            <Slide
              course={x}
              useWebP={useWebP}
              slideChangeIndex={activeSlide}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
