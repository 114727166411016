import React from "react";
import top_section_illu from "../../assets/top_section_illu.svg";
import { motion } from "framer-motion";
import "./firstSection.scss";
import { useTranslation } from "react-i18next";
const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const animations = {
  variation_1: {
    exit: { y: "50%", opacity: 0, transition: { delay: 0.8, ...transition } },
    enter: {
      y: "0%",
      opacity: 1,
      transition: { delay: 0.5, ...transition },
    },
  },
  variation_2: {
    exit: { y: "50%", opacity: 0, transition: { delay: 1.2, ...transition } },
    enter: {
      y: "0%",
      opacity: 1,
      transition: { delay: 0.9, ...transition },
    },
  },
  variation_3: {
    exit: { y: "50%", opacity: 0, transition: { delay: 1.6, ...transition } },
    enter: {
      y: "0%",
      opacity: 1,
      transition: { delay: 1.3, ...transition },
    },
  },
  variation_4: {
    exit: { y: "50%", opacity: 0, transition: { delay: 1.6, ...transition } },
    enter: {
      y: "0%",
      opacity: 1,
      transition: { delay: 1.6, ...transition },
    },
  },
};
export default function FirstSection({ authCallBack = () => {}, authType }) {
  // const { t } = useTranslation();

  const { t, i18n } = useTranslation();

  return (
    <motion.div className="container first-section">
      <div
        className="columns is-centered"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <div className="column px-6">
          <motion.h3
            className="is-size-1 has-text-weight-bold has-text-danger"
            variants={animations.variation_1}
            initial="exit"
            animate="enter"
          >
            {t("intro_line_1")}
          </motion.h3>
          <motion.h3
            className="is-size-1 has-text-weight-bold has-text-danger "
            variants={animations.variation_2}
            initial="exit"
            animate="enter"
          >
            {t("intro_line_2")}
          </motion.h3>
          <motion.h3
            className="is-size-1 has-text-weight-bold has-text-danger "
            variants={animations.variation_3}
            initial="exit"
            animate="enter"
          >
            {t("intro_line_3")}
          </motion.h3>
          {/* <motion.p
            variants={animations.variation_4}
            initial="exit"
            animate="enter"
            className="is-hidden-touch mt-2 is-size-6"
          >
            Your gateway to vast knowledge
          </motion.p> */}
          {authType !== "signedIn" && (
            <motion.a
              className="button is-primary is-rounded has-text-weight-bold mt-4"
              whileHover={{
                scale: 1.2,
              }}
              whileTap={{ scale: 0.9 }}
              onClick={authCallBack.bind(this, "signup")}
              variants={animations.variation_4}
              initial="exit"
              animate="enter"
            >
              Sign up with Khebrat
            </motion.a>
          )}
        </div>
        <motion.div
          className="column has-text-centered-touch"
          variants={animations.variation_1}
          initial="exit"
          animate="enter"
        >
          <img
            className=" illustraion"
            src={top_section_illu}
            alt="showing illustration"
          ></img>
        </motion.div>
      </div>
    </motion.div>
  );
}
